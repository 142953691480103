export const PRODUCT_UPDATE_PAGE_URL =
    "https://grow-with-verdi.notion.site/Verdi-Product-Updates-1dfba6ac5d1f47a5baf7affefeb65aed";

/**
 * Indicates current version of product update.
 *
 * Change this number when the product update is updated to reset the dismissed state
 * and open the product update on startup again.
 */
export const PRODUCT_UPDATE_VERSION_NUMBER = 1;
