import { useCallback, useEffect } from "react";
import { useModal } from "src/hooks/useModal";

import { getValue, LocalStorageKey, setValue } from "../../services/localStorage";
import { ProductUpdateModal } from "./components/ProductUpdateModal";
import { PRODUCT_UPDATE_VERSION_NUMBER } from "./constants";
import { type ProductUpdate } from "./productUpdates/productUpdates";

let openedThisSession = false;
/**
 * Hook for managing product update modal state and automatically opening the modal on startup
 */
export function useProductUpdateModal({ productUpdate }: { productUpdate: ProductUpdate }) {
    const { openModal, closeModal } = useModal();

    const productUpdateDismissed = getValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED);

    const setProductUpdateDismissed = useCallback(() => {
        setValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED, true);
    }, []);

    const openProductUpdateModal = useCallback(
        (date: Date, component: React.ReactNode, showDismissCheckbox?: boolean) => {
            openModal(
                <ProductUpdateModal
                    onClose={closeModal}
                    onDismiss={setProductUpdateDismissed}
                    date={date}
                    showDismissCheckbox={showDismissCheckbox}
                >
                    {component}
                </ProductUpdateModal>,
            );
        },
        [openModal, closeModal, setProductUpdateDismissed],
    );

    // Reset the key if the version has changed
    useEffect(() => {
        const productUpdateVersion = getValue(LocalStorageKey.PRODUCT_UPDATE_VERSION);
        // Initial or reset the key if the version has changed
        if (productUpdateVersion === undefined || productUpdateVersion !== PRODUCT_UPDATE_VERSION_NUMBER) {
            setValue(LocalStorageKey.PRODUCT_UPDATE_VERSION, PRODUCT_UPDATE_VERSION_NUMBER);
            setValue(LocalStorageKey.PRODUCT_UPDATE_DISMISSED, false);
        }
    }, []);

    // Trigger the modal to open once per session, if the product update is not marked as dismissed
    useEffect(() => {
        if (!productUpdateDismissed && !openedThisSession && productUpdate.openOnStartup) {
            openProductUpdateModal(productUpdate.date, productUpdate.component, !productUpdateDismissed);
            openedThisSession = true;
        }
    }, [openProductUpdateModal, productUpdate, productUpdateDismissed]);

    return { openProductUpdateModal, productUpdateDismissed };
}
