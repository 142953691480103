import { NewReleases } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { ProductUpdateMenuButton } from "./components/ProductUpdateMenuButton";
import { ProductUpdateMenuLink } from "./components/ProductUpdateMenuLink";
import { PRODUCT_UPDATE_PAGE_URL } from "./constants";
import { PRODUCT_UPDATES, type ProductUpdate } from "./productUpdates/productUpdates";
import { useProductUpdateModal } from "./useProductUpdateModal";

/**
 * Icon button, with dropdown menu of product updates
 */
export function ProductUpdateMenu({ trigger }: { trigger?: React.ReactNode }) {
    const { t } = useTranslation("modules", { keyPrefix: "productUpdate.menu" });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const { openProductUpdateModal, productUpdateDismissed } = useProductUpdateModal({
        productUpdate: PRODUCT_UPDATES.find((update) => update.openOnStartup) ?? PRODUCT_UPDATES[0],
    });

    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {trigger ? (
                <MenuItem
                    component={Button}
                    key={`ProductUpdateMenu`}
                    sx={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    onClick={handleOpen}
                >
                    {trigger}
                </MenuItem>
            ) : (
                <IconButtonWithTooltip onClick={handleOpen} icon={<NewReleases />} title={t("title")} />
            )}
            <Menu anchorEl={anchorEl} keepMounted style={{ zIndex: 1400 }} open={open} onClose={handleClose}>
                {PRODUCT_UPDATES.map((update: ProductUpdate) => (
                    <ProductUpdateMenuButton
                        key={`ProductUpdateMenu_${update.title}`}
                        title={update.title}
                        date={update.date}
                        tagVariant={update.tagVariant}
                        onClick={() => {
                            openProductUpdateModal(update.date, update.component!, !productUpdateDismissed);
                            handleClose();
                        }}
                    />
                ))}
                <Box sx={{ my: 2, borderTop: "1px solid #e0e0e0" }} />
                <ProductUpdateMenuLink title={t("allUpdates")} url={PRODUCT_UPDATE_PAGE_URL} onClick={handleClose} />
            </Menu>
        </>
    );
}
