import { HashedDaySchedule, Zone } from "verdiapi/dist/Models";
import { IrrigationEventProto } from "verdiapi/dist/Models/Scheduling/IrrigationEventProto";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import type MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";
import { getConnectedZones, getDeviceType } from "./deviceAttributes";

/**
 * Irrigating is true if any valve is open
 * returns boolean or null if uncertain
 */
export function isIrrigating({ model }: { model: MapEntityBase["model"] }) {
    const type = getDeviceType({ model });
    const zones = getConnectedZones({ model });
    /**
     * Some sensors have their model set to have valves and record valve state in recentData.valveState for verification purposes, but
     * are not actually connected to valves in reality.
     *
     * In this case, check config valveCount since it accurately reflects the true state of the device,
     * instead ofrecentData.valveState
     */
    if (DeviceConfigurationsByType[type]?.valveCount === 0) {
        return false;
    }

    if (model.recentData.valveState !== undefined) {
        const valveStates = [
            model.recentData.valveState.value % 2 > 0,
            Math.floor(model.recentData.valveState.value / 2) % 2 > 0,
        ];
        // if it's a single valve or sensor only device, return the valve state
        if (DeviceConfigurationsByType[type].valveCount < 2) {
            if (valveStates[0] === valveStates[1]) {
                return valveStates[0];
            }
            return null;
        }
        if (zones) {
            if (zones.length === 1) {
                const zone = zones[0];
                const valve = model.connectedZoneIDs.indexOf(zone.id);
                if (valve !== -1) {
                    return valveStates[valve];
                }
            }
        }
    }
    return null;
}

/**
 * Returns a percentage 0-100 of irrigation completed
 */
export function percentageOfIrrigationCompleted({ closestEvent }: { closestEvent: IrrigationEventProto }) {
    const irrigationStartTime = closestEvent ? closestEvent.startTime : 0; // new Date(Date.now() - 1000 * 60 * 30);
    const irrigationEndTime = closestEvent ? closestEvent.endTime : 0; // ;

    let percentDone =
        (Date.now() - irrigationStartTime.valueOf()) /
        Math.max(2, irrigationEndTime.valueOf() - irrigationStartTime.valueOf());
    if (percentDone > 1) {
        percentDone = 1;
    }
    if (percentDone < 0) {
        percentDone = 0;
    }
    return percentDone * 100;
}

interface DeviceIrrigationEventState {
    shouldBeIrrigating: boolean;
    closestEvent: IrrigationEventProto | undefined;
    zoneIrrigationStateUncertain: boolean;
}
export function getDeviceIrrigationEventState({ model }: { model: MapEntityBase["model"] }) {
    const newState: DeviceIrrigationEventState = {
        shouldBeIrrigating: false,
        closestEvent: undefined,
        zoneIrrigationStateUncertain: false,
    };
    const zones = getConnectedZones({ model });

    if (zones) {
        newState.shouldBeIrrigating = false;
        newState.closestEvent = undefined;
        let atLeastOneZoneUncertain = false;
        zones.forEach((z) => {
            const { shouldBeIrrigating, closestEvent, uncertain } = getCurrentIrrigationInfoForZone({
                zone: z,
                otherEvents: newState.closestEvent ? [newState.closestEvent] : [],
            });
            newState.shouldBeIrrigating = newState.shouldBeIrrigating || shouldBeIrrigating;
            newState.closestEvent = closestEvent;
            if (uncertain) {
                atLeastOneZoneUncertain = true;
            }
        });
        if (atLeastOneZoneUncertain && !newState.shouldBeIrrigating) {
            newState.zoneIrrigationStateUncertain = true;
        } else {
            newState.zoneIrrigationStateUncertain = false;
        }
    }
    return newState;
}

interface getCurrentIrrigationInfoForZoneParams {
    zone: Zone;
    otherEvents: IrrigationEventProto[];
}
function getCurrentIrrigationInfoForZone({ zone, otherEvents = [] }: getCurrentIrrigationInfoForZoneParams) {
    const min = 1000 * 60;
    if (!zone) {
        return {
            shouldBeIrrigating: false,
            closestEvent: undefined,
            uncertain: true,
        };
    }
    // @ts-ignore
    const uncertain = zone.recentData.zoneIrrigationScheduled === 0.5;
    const relevantDays = [
        HashedDaySchedule.getHashRecord(new Date(Date.now()), zone.id),
        HashedDaySchedule.getHashRecord(new Date(Date.now() + min * 60 * 24), zone.id),
        HashedDaySchedule.getHashRecord(new Date(Date.now() - min * 60 * 24), zone.id),
    ];
    const eventsHappeningNow: IrrigationEventProto[] = [];
    const closeEvents: IrrigationEventProto[] = [];
    let shouldBeIrrigating = false;
    const processEvent = (e: IrrigationEventProto) => {
        if (e === undefined) {
            return;
        }
        if (e.startTime.valueOf() < Date.now() && e.endTime.valueOf() > Date.now()) {
            eventsHappeningNow.push(e);
            shouldBeIrrigating = true;
        }
        if (e.startTime.valueOf() < Date.now() + min * 30 && e.endTime.valueOf() > Date.now() - min * 30) {
            closeEvents.push(e);
        }
    };
    relevantDays.forEach((rd) => {
        rd.includedEvents.forEach(processEvent);
    });
    otherEvents.forEach(processEvent);

    const closestEvent = eventsHappeningNow[0] || closeEvents[0] || undefined;
    return {
        shouldBeIrrigating,
        closestEvent,
        uncertain,
    };
}
