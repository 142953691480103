import { TAG } from "../../../components/generic/Tag/types";
import { MapIconsProductUpdate } from "./mapIcons/MapIconProjectUpdate";

// Will link to the public product update page
export type ProductUpdate = {
    title: string;
    url: string;
    date: Date; // in UTC
    tagVariant?: TAG;
    openOnStartup?: boolean; // should only be set on ONE item at a time
    component: React.ReactNode;
};

export const PRODUCT_UPDATES: ProductUpdate[] = [
    {
        // TODO fix this link after the product update is published
        url: "https://grow-with-verdi.notion.site/Verdi-Product-Updates-1dfba6ac5d1f47a5baf7affefeb65aed",
        openOnStartup: true,
        title: "Icon Overhaul",
        date: new Date("2024-12-29"),
        tagVariant: TAG.NEW,
        component: <MapIconsProductUpdate />,
    },
];
