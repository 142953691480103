import { isManualMode } from "../../../../../services/deviceDataAccessors/manualMode";
import type DeviceMapEntity from "../../../../../services/mapManagement/mapEntities/DeviceMapEntity";
import type MapEntityBase from "../../../../../services/mapManagement/mapEntities/MapEntityBase";
import {
    makeBadgeParams,
    makeColorParams,
    makeProgressRingParams,
    makeWarnings,
} from "../../../../../services/mapManagement/mapEntities/shared";
import { menuBadgeSize, menuIconSize } from "../constants";
import { FullIcon } from "./FullIcon";
import { makeIconGraphicConfig } from "./IconGraphic/constants";
import { IconGraphic } from "./IconGraphic/IconGraphic";

interface IconAvatarProps {
    mapEntity: MapEntityBase | DeviceMapEntity;
    size?: number;
    badgeSize?: number;
}
/**
 * A simplified device icon component that renders a FullIcon for deviceMapItem
 * Used in cards and menus without map-specific functionality
 */
export function IconAvatar({ mapEntity, size = menuIconSize, badgeSize = menuBadgeSize }: IconAvatarProps) {
    const { status } = makeWarnings({
        model: mapEntity.model,
        type: mapEntity.model.type,
    });
    const manualMode = isManualMode({ model: mapEntity.model });

    const { iconGraphicConfig } = makeIconGraphicConfig({
        manualMode,
        deviceType: mapEntity.model.type,
    });

    const notesColorParams = makeColorParams({
        deviceType: mapEntity.model.type,
        notes: mapEntity.model.notes,
    });

    const badgeParams = makeBadgeParams({ warningStatus: status, size: badgeSize });

    return (
        <FullIcon
            key={mapEntity.id}
            size={size}
            colorParams={notesColorParams}
            badgeParams={badgeParams}
            hoverEffect={false}
            progressRingParams={makeProgressRingParams({ model: mapEntity.model })}
        >
            <IconGraphic size={size * 0.6} {...iconGraphicConfig} />
        </FullIcon>
    );
}
