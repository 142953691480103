import { Button, MenuItem, Stack } from "@mui/material";

import { Tag } from "../../../components/generic/Tag/Tag";
import { TAG } from "../../../components/generic/Tag/types";
import { DateIcon } from "./DateIcon";

interface ProductUpdateMenuButtonProps {
    title: string;
    date: Date;
    tagVariant?: TAG;
    onClick?: () => void;
}
/**
 * Menu item as a button for product update, shows icon, title, and tag
 */
export function ProductUpdateMenuButton({ title, date, tagVariant, onClick }: ProductUpdateMenuButtonProps) {
    return (
        <MenuItem
            key={`ProductUpdateMenu_${title}`}
            component={Button}
            onClick={onClick}
            sx={{
                gap: 2,
                width: "100%",
                textTransform: "none",
            }}
        >
            {date && (
                <Stack direction={"column"} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <DateIcon date={date} />
                </Stack>
            )}
            {title}
            {tagVariant && <Tag wrapperSx={{ marginLeft: 2 }} tagVariant={tagVariant} />}
        </MenuItem>
    );
}
