import "../Navigation.css";

import { Divider, Drawer as MuiDrawer, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProductUpdateMenu } from "src/modules/productUpdate/ProductUpdateMenu";

import { LanguageSelector } from "../../../components/specialized/LanguageSelector/LanguageSelector";
import { ProvideFeedbackButton } from "../../../components/specialized/ProvideFeedbackModal/ProvideFeedbackButton";
import { AdminToolsNavList } from "../NavLinks";
import { AccountSelect } from "./AccountSelect";
import { AOISelect } from "./AOISelect";

interface DrawerProps {
    multipleAOI: boolean;
    loggedIn: boolean;
    open: boolean;
    onClose: () => void;
    mobileMenuAnchorSetter: (anchor: HTMLElement | null) => void;
    admin: boolean;
}

export function Drawer({ multipleAOI, loggedIn, open, onClose, mobileMenuAnchorSetter, admin }: DrawerProps) {
    const { t } = useTranslation(["modules"], { keyPrefix: "navigation.drawer" });

    return (
        <MuiDrawer anchor={"left"} keepMounted open={open} onClose={onClose} sx={{ zIndex: 1200, overflow: "hidden" }}>
            <Stack height={"100%"} flexDirection={"column"} overflow={"scroll"}>
                <Toolbar style={{ minHeight: "var(--nav-bar-height, 55px)", maxHeight: "var(--nav-bar-height)" }} />
                {admin && <AccountSelect />}
                {multipleAOI && <AOISelect />}
                <Divider sx={{ mb: 2 }} />
                {admin && (
                    <div style={{ marginTop: 16 }}>
                        <MenuItem key={`admin-links-title`}>
                            <b>Admin Links</b>
                        </MenuItem>
                        {AdminToolsNavList.map((NL) => (
                            <MenuItem
                                key={`admin-links-${NL.url}`}
                                component={Link}
                                to={NL.url}
                                onClick={() => {
                                    mobileMenuAnchorSetter(null);
                                }}
                            >
                                {NL.name}
                            </MenuItem>
                        ))}
                    </div>
                )}
                {loggedIn && (
                    <div style={{ marginTop: "auto" }}>
                        <ProductUpdateMenu trigger={t("title", { keyPrefix: "productUpdate.menu" })} />
                        <MenuItem
                            key={`NavBarStandardLinks/settings`}
                            component={Link}
                            to={"/settings"}
                            onClick={() => {
                                mobileMenuAnchorSetter(null);
                            }}
                        >
                            {t("settings", { keyPrefix: "navigation.navLinks" })}
                        </MenuItem>
                        <ProvideFeedbackButton />
                        <LanguageSelector flagSize={14} includeLabel={true} />
                        <MenuItem
                            key={`logout`}
                            onClick={() => {
                                localStorage.setItem("userData", "");
                                window.location.reload();
                            }}
                        >
                            {t("logOut", { ns: "modules", keyPrefix: "navigation.navBar" })}
                        </MenuItem>
                    </div>
                )}
            </Stack>
            <MenuItem
                sx={{
                    background: "#00000022",
                    width: "100%",
                }}
                onClick={onClose}
            >
                {t("back")}
            </MenuItem>
        </MuiDrawer>
    );
}
