import { Typography } from "@mui/material";

import { textVariants } from "../constants";

/**
 * Product update content for Map Icons Overhaul
 */
export function MapIconsProductUpdate() {
    return (
        <>
            <section>
                <Typography variant={textVariants.title}>Smarter Map Icons: See Your Farm at a Glance 🗺️</Typography>
                <Typography variant={textVariants.body}>
                    We're excited to share our latest improvement to the Verdi dashboard: completely redesigned map
                    icons that make monitoring your farm easier and more intuitive. We've focused on what matters most -
                    helping you quickly spot active irrigations, potential issues, and device status without cluttering
                    your view.
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>All-New Device Symbols 🎨</Typography>
                <Typography variant={textVariants.body}>
                    We've given your devices a fresh new look on the map! You may notice:
                    <br />
                    - Redesigned Valve and Sensor for better visibility
                    <br />- Devices in Manual Mode are now clearly shown with an `M` in the center
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>Smarter Display for Better Visibility 🎯</Typography>
                <Typography variant={textVariants.body}>
                    - Inactive devices appear as simple dots to reduce map clutter. Hover over them to expand, and click
                    as usual to view
                    <br />- Devices that require your attention (such as irrigating or displaying a warning)
                    automatically show as full icons
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>Clear Status Indicators 🚦</Typography>
                <Typography component={"div"}>
                    <Typography variant={textVariants.subtitle}>Warning Badges</Typography>
                    We've made it much easier to spot what needs your attention:
                    <br />
                    - Orange: Warning that needs review
                    <br />- Red: Error requiring attention
                    <Typography variant={textVariants.subtitle}>Irrigation Ring 💧</Typography>
                    The new blue ring shows the irrigation status on your device:
                    <br />
                    - Spinning: Manual mode valve is transitioning
                    <br />
                    - Partial fill: Shows irrigation schedule progress
                    <br />- Full ring: Valve manually opened
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>Intelligent Device Grouping 📍</Typography>
                <Typography variant={textVariants.body}>
                    As you zoom out, nearby devices automatically group together to give you a clearer overview:
                    <br />
                    - Groups show total device count
                    <br />
                    - Inherit status indicators from contained devices
                    <br />- One click expands to show all devices in the group
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>How This Helps Your Operation</Typography>
                <Typography variant={textVariants.body}>
                    - Faster Issue Detection: Critical alerts and warnings stand out immediately
                    <br />
                    - Cleaner View: Focus on active operations while maintaining visibility of all devices
                    <br />
                    - Easier Navigation: Smart grouping helps manage farms of any size
                    <br />- Quick Status Checks: Use groups to easily view an entire field
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>Getting Started</Typography>
                <Typography variant={textVariants.body}>
                    The new icons are live now in your dashboard. Here are a few tips:
                    <br />
                    1. Try zooming in/out to see how device grouping works for your layout
                    <br />
                    2. Hover over any dot to preview device details
                    <br />
                    3. Look for the blue ring to track irrigation progress
                </Typography>
            </section>

            <section>
                <Typography variant={textVariants.title}>We're Here to Help 💭</Typography>
                <Typography variant={textVariants.body}>
                    As always, we want to make sure these changes work for you. Have feedback or questions? We're just
                    an email away at support@verdiag.com or use the feedback form in the Verdi dashboard.
                    <br />
                    <br />
                    Happy farming! 🌱
                    <br />
                    The Verdi Team
                </Typography>
            </section>
        </>
    );
}
