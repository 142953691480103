import { MasterIndex } from "verdiapi";
import { generateLabelTagFromEUID } from "verdiapi/dist/HelperFunctions/GenerateLabelText";
import { DeviceConfiguration, DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import type MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";

export function isAutomated({ model }: { model: MapEntityBase["model"] }) {
    return (
        model.saplingAutomationSettings?.automationEnabled ||
        model.sproutAutomationSettings?.automationEnabled !== "none"
    );
}

export function isSensorOnly({ model }: { model: MapEntityBase["model"] }) {
    const type = model.type as DeviceConfiguration["type"];
    return DeviceConfigurationsByType[type]?.valveCount === 0;
}

export function getConnectedZones({ model }: { model: MapEntityBase["model"] }) {
    return model?.connectedZoneIDs ? [...model.connectedZoneIDs.map((zid: string) => MasterIndex.zone.byID[zid])] : [];
}

export function getDeviceType({ model }: { model: MapEntityBase["model"] }) {
    return model.type as DeviceConfiguration["type"];
}

export function isValve({ deviceType }: { deviceType: DeviceConfiguration["type"] }) {
    return (
        DeviceConfigurationsByType[deviceType]?.valveType && DeviceConfigurationsByType[deviceType].valveType !== "none"
    );
}

export function isSensor({ deviceType }: { deviceType: DeviceConfiguration["type"] }) {
    return DeviceConfigurationsByType[deviceType]?.valveType === "none";
}

/**
 * Get the friendly name for a device
 * If the device's name is the same as its EUI, return the short code (eg. KEZ-283) as the friendly name
 * else return the device's user-defined name
 */
export function getDisplayName({ model }: { model: MapEntityBase["model"] }) {
    const id = model?.id;
    let shortCode = "";
    try {
        if (isEUIValid(id)) {
            shortCode = generateLabelTagFromEUID(id.replace("eui-", ""));
        }
    } catch (e) {
        console.error(e);
    }

    if (id && id === model.name) {
        return shortCode || (model.name ?? id);
    }
    return model.name ?? id;
}

const isEUIValid = (id: string) => id?.includes("eui-") && id.replace("eui-", "")?.length === 16;
